import LogoImage from "../assets/svg/final-logo.svg";
// import LogoImage from "../assets/images/hp_logo2.png";
import LogoImageMondored from "../assets/images/mondored_logo3.png";
import headerLogoMondored from "../assets/images/download-mondored.png";
import LogoImageBakeca from "../assets/images/bakeca-logo.png";
import headerLogoHp from "../assets/images/hp_logo.png";
import Pin from "../assets/svg/pin.svg";
import PinMondo from "../assets/svg/pin_mondo.svg";
import PinBakeca from "../assets/svg/pin_bakeca.svg";

const platformConditionals = () => {
  const housePlusStyles = {
    colorsProbaChart: ["#11606d", "#00b1c4"],
    logo: LogoImage,
    bannerPdfClass: "first_page_img",
    bannerPdfHoc: headerLogoHp,
    pdfTitle: "Report_houseplus_",
    pdfHomeIconColor: "0aa5b5",
    pdfComparedIcon:
      "https://uploads-ssl.webflow.com/6377c6cc4ffcbe580af46c7f/65ca1e667c28fed1c223cf5c_home%20(9).png",
    owner: 1,
    bannerText: "Houseplus",
    reportTableColor: "#034349",
    reportTableColorAction: "#078ea630",
    reportTableColorHeader: "#ecf7f8",
    reportTableWhite: "#FFF7FA",
    packageUrl: "/getPaymentUrl",
    staticMapPin: Pin,
  };

  const mondoStyles = {
    colorsProbaChart: ["#270609", "#7a3e3e"],
    logo: LogoImageMondored,
    bannerPdfClass: "first_page_img_mondored",
    bannerPdfHoc: headerLogoMondored,
    pdfTitle: "Report_mondored_",
    pdfHomeIconColor: "af5959",
    pdfComparedIcon:
      "https://uploads-ssl.webflow.com/6377c6cc4ffcbe580af46c7f/65ca1eb6757b9efe4734e649_home%20(6).png",
    owner: 2,
    bannerText: "Red-Estate",
    reportTableColor: "#370A0E",
    reportTableColorAction: "#b0283230",
    reportTableWhite: "#FFF7FA",
    packageUrl: "/whiteLabel/admin/get-client/subscription/pack",
    staticMapPin: PinMondo,
  };

  const bakecaStyles = {
    colorsProbaChart: ["#80ba27", "#bde383"],
    logo: LogoImageBakeca,
    bannerPdfClass: "first_page_img_bakeca",
    bannerPdfHoc: LogoImageBakeca,
    pdfLogoSize: "120px",
    pdfTitle: "Report_bakeca_",
    pdfHomeIconColor: "65a30d",
    pdfComparedIcon:
      "https://uploads-ssl.webflow.com/6377c6cc4ffcbe580af46c7f/65eb3da696d945629d2a176b_home%20(14).png",
    owner: 3,
    bannerText: "Bakeca",
    reportTableColor: "#0f172a",
    reportTableColorAction: "#bde383",
    reportTableWhite: "#f1ffe0",
    packageUrl: "",
    staticMapPin: PinBakeca,
  };

  switch (process.env.REACT_APP_PLATFORM) {
    case "HOUSEPLUS":
      return housePlusStyles;
    case "MONDORED":
      return mondoStyles;
    case "BAKECA":
      return bakecaStyles;
    default:
      return housePlusStyles;
  }
};

export default platformConditionals;
